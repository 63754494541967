export default {
  "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "hu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magyar"])},
  "ro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Română"])},
  "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deutsch"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome"])},
  "no_chat_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No chat history"])},
  "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "clearservicetempo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo"])},
  "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "required_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required field"])},
  "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy to clipboard"])},
  "reader_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reader view"])},
  "bookmark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bookmark"])},
  "satisfying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satisfying"])},
  "inadequate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inadequate"])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
  "print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "describe_problem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please describe the problem"])},
  "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["project"])},
  "no_files_uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No documents uploaded to this project"])},
  "upload_files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload files"])},
  "drop_files_here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drop files here"])},
  "select_or_create_project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select or create project"])},
  "new_project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New project"])},
  "search_or_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search or create"])},
  "prompt_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ask Sinclair (Shift + Enter for multiline)"])},
  "ex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ex"])},
  "sure_want_to_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure want to delete"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "user_chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat list"])},
  "bookmarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bookmarks"])},
  "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["closed"])},
  "characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["characters"])},
  "words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["words"])},
  "file_upload_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
  "sign_up_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new account"])},
  "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register new account"])},
  "login_with_google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in with Google account"])},
  "login_with_facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belépek Facebook fiókkal"])},
  "login_with_github": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in with GitHub account"])},
  "and_start_using_uponai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and start using Upon:AI"])},
  "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or"])},
  "thread_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help for the project"])},
  "files_uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of files uploaded out of the possible maximum"])},
  "filter_to_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter conversation to this project"])},
  "shows_the_whole_chat_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display the entire chat history of the assistant"])},
  "shows_the_threads_chat_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display the chat history for the project only"])},
  "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["default"])},
  "steps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let's get acquainted!"])},
  "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
  "finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
  "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
  "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skip"])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["year"])},
  "yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yearly"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["month"])},
  "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["monthly"])},
  "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["day"])},
  "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["daily"])},
  "student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student"])},
  "startup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["StartUp"])},
  "creative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creative"])},
  "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business"])},
  "soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soon"])},
  "choose_this": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose this"])},
  "congrats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congratulations"])},
  "plan_chosen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your chosen package"])},
  "expires": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid until"])},
  "i_agree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I agree"])},
  "with_terms_and_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["with the terms and conditions"])},
  "terms_and_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms and Conditions"])},
  "forward_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forward to all"])},
  "forward_leader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forward to group leader"])},
  "task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task"])},
  "task_close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close task"])},
  "clearserviceforecast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schedule"])},
  "assistants": {
    "live_support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hello Team"])},
    "buddy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buddy"])},
    "image_prompter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image prompter"])},
    "developer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Developer"])},
    "translator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translator"])},
    "web_crawler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web crawler"])},
    "brainiac": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brainiac"])},
    "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teacher"])},
    "copywriter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copywriter"])},
    "bookwriter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Author"])}
  },
  "notifications": {
    "err_fill_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in required fields"])},
    "file_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document deleted"])},
    "wrong_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please insert a valid prompt"])},
    "server_conn_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to connect to the server"])},
    "unauthorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unauthorized"])},
    "wrong_file_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not process file"])},
    "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not found"])},
    "error_logging_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System error. Try again!"])},
    "invalid_login_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid login data"])},
    "user_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User inactive"])},
    "already_logged_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already logged in"])},
    "server_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server error"])},
    "thanks_for_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for your feedback!"])},
    "invalid_lang_iso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid language ISO code"])},
    "language_changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language changed"])},
    "language_loaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language loaded"])},
    "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copied to clipboard"])},
    "thread_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project not found"])},
    "thread_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project deleted"])},
    "file_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File not found"])},
    "file_name_too_long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File name too long"])},
    "file_too_big": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File size too large"])},
    "text_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text not found"])},
    "max_files_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum number of files reached"])},
    "invalid_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid token"])},
    "user_not_valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid oAuth user"])},
    "user_already_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User already registered with the same credentials"])},
    "popup-blocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable pop-up windows"])},
    "account-exists-with-different-credential": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account already exists with a different login method"])},
    "error_saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while saving"])},
    "bookmark_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You bookmarked the conversation"])},
    "bookmark_removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bookmark removed"])},
    "no_energy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have enough energy to perform the action"])},
    "you_must_agree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must accept the terms of use"])}
  },
  "steps-locals": {
    "personal-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Information"])},
    "choose-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packages"])},
    "organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization"])},
    "free_trial_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["free trial period"])},
    "free_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free trial period during which you can enjoy the benefits of the Upon:AI package you have chosen without restrictions. After this, you can subscribe to the package or cancel it for free."])},
    "number_choosable_mi_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["assistant"])},
    "number_choosable_mi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your subscription package includes artificial intelligence assistants of different levels and capabilities. Choose the package that best serves your needs."])},
    "basic_mis_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic AIs"])},
    "basic_mis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These assistants are faster but have more limited capabilities. They are ideal for simpler tasks, and the number of characters that can be entered is also limited (max. 2000 characters)."])},
    "advanced_mis_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced AIs"])},
    "advanced_mis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These assistants are tuned for data analysis, text writing and other complex tasks. While they may be slower than basic AIs, they are much more intelligent."])},
    "energy_per_day_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["energy / day"])},
    "energy_per_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Every operation you perform with the AI requires a certain amount of energy. Basic AIs use less energy while advanced AIs use more."])},
    "max_chars_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["character input"])},
    "max_chars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your subscription package determines how much text you can enter at once to the assistant. Larger packages handle larger amounts of text. The amount of text input depends on the particular assistant."])},
    "chat_support_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online support in chat"])},
    "chat_tel_support_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online support in chat and via phone"])},
    "chat_tel_support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you need help, you can get live support via chat or phone, depending on the package."])},
    "upload_file_analysis_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload text file, spreadsheet or website for data processing"])},
    "upload_file_analysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certain AI models are capable of processing even hundreds of pages of scanned or text documents, spreadsheets, etc., as well as the text of websites, and use them in answering."])},
    "choosable_mis_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistants"])},
    "choosable_mis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depending on your subscription package, you have access to various artificial intelligence (AI) assistants, each with its own special \"personality\" and capabilities."])},
    "choosable_mis_highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistants highlighted in yellow are more advanced AIs. Some of these - such as the Braintrust - have file upload or web page analysis capabilities."])},
    "choosable_mis_highlight_details_link_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can find detailed descriptions of the different assistants here..."])},
    "professional_newsletters_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["professional newsletters"])},
    "professional_newsletters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Useful information, tips, tricks for more efficient and easier use of Upon:AI assistants. The newsletters arrive approximately weekly."])},
    "plans_subheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try out all the features for free for 14 days, then choose the package that suits you best."])},
    "company_subheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please share with us the basic information about your business or institution where you are actively engaged. This data helps us better understand your needs and provide a more personalized service."])},
    "company_or_school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of Business or Institution"])},
    "example_institute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business or Academic Institution"])},
    "company_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
    "example_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Executive, Marketing, Student, Teacher"])},
    "company_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brief Description"])},
    "example_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What is your main task or activity? What principles do you work by? What type of studies are you pursuing?"])}
  }
}